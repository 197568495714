import React, { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/reduxHooks";
import { favouritesReducerActions } from "../../../../store/reducers/favouritesReducer";
import { COMPLEX_ID } from "../../../../utils/constants/queryParamsKeys";
import { NewResidentalComplexModel } from "../../../../utils/models/residentialComplexModel";
import { useUpdateFavourites } from "../../service/mutations";
import FavouriteIcon from "../../../../assets/icons/FavouriteIcon";
import styles from "../../Index.module.scss";
import { layoutLang } from "../../../../utils/language/layout";
import { useRouter } from "next/router";
type Props = {
  data: NewResidentalComplexModel;
  type?: string;
};
const ComplexHeart: React.FC<Props> = ({
  data,
  type
}) => {
  const {
    locale
  } = useRouter();
  const dispatch = useAppDispatch();
  const updateFavourites = useUpdateFavourites();
  const {
    isAuth
  } = useAppSelector(state => state.userReducer);
  const {
    appendComplex,
    removeComplex
  } = favouritesReducerActions;
  const {
    complex: favourites
  } = useAppSelector(state => state.favouritesReducer);

  // checked
  const checked = useMemo(() => favourites?.some(item => item.id === data?.id), [favourites]);

  // prevent default
  const handlePreventDefault = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.stopPropagation();
  };

  // change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isAuth) {
      updateFavourites.mutate({
        key: COMPLEX_ID,
        id: data?.id
      });
    }
    if (e.target.checked) {
      dispatch(appendComplex(data));
    } else {
      dispatch(removeComplex(data?.id));
    }
  };
  return <label className={`${styles.heart} ${checked ? styles.checked : styles.unChecked}`} onClick={handlePreventDefault} aria-hidden data-sentry-component="ComplexHeart" data-sentry-source-file="ComplexHeart.tsx">
      <input type="checkbox" checked={checked} onChange={handleChange} />
      <FavouriteIcon data-sentry-element="FavouriteIcon" data-sentry-source-file="ComplexHeart.tsx" />
      {type && <span>{layoutLang[String(locale)]["Saqlanganlar"]}</span>}
    </label>;
};
export default ComplexHeart;