import React from "react";
import style from "../../Index.module.scss";
import MetroIcon from "../../../../assets/icons/MetroIcon";
import ShopIcon from "../../../../assets/icons/ShopIcon";
import ParkIcon1 from "../../../../assets/icons/ParkIcon1";
import BusIcon from "../../../../assets/icons/BusIcon";
import WalkIcon from "../../../../assets/icons/WalkIcon";
import CarIcon from "../../../../assets/icons/CarIcon";
import { useRouter } from "next/router";
import { NearestPlaces } from "../../utils/models/ComplexModel";
import { RU } from "../../../../utils/constants/locales";
type Props = {
  data: NearestPlaces[] | undefined;
  type?: string;
};
const nearestIcons = {
  metro: <MetroIcon />,
  shopping_centre: <ShopIcon />,
  park: <ParkIcon1 />,
  on_foot: <WalkIcon />,
  on_bus: <BusIcon />,
  in_car: <CarIcon />
};
const Placements: React.FC<Props> = ({
  data,
  type
}) => {
  const {
    locale
  } = useRouter();
  return <div className={style.placements} data-sentry-component="Placements" data-sentry-source-file="Placements.tsx">
      {type && data && data?.length > 0 ? <div className={style.placements_item}>
          <div className={style.placements_item_left}>
            {nearestIcons[data[0]?.facility as keyof typeof nearestIcons]}
            <span>{data[0]?.[`name_${locale}` as keyof (typeof data)[0]]}</span>
          </div>
          <div className={style.placements_item_right}>
            {nearestIcons[data[0]?.transportation as keyof typeof nearestIcons]}
            <span>{`${data[0]?.duration} ${locale === RU ? "мин" : "min"}`}</span>
          </div>
        </div> : data?.map(item => <div className={style.placements_item}>
            <div className={style.placements_item_left}>
              {nearestIcons[item?.facility as keyof typeof nearestIcons]}
              <span>{item[`name_${locale}` as keyof typeof item]}</span>
            </div>
            <div className={style.placements_item_right}>
              {nearestIcons[item?.transportation as keyof typeof nearestIcons]}
              <span>
                {item?.duration} {locale === RU ? "мин" : "min"}
              </span>
            </div>
          </div>)}
    </div>;
};
export default Placements;